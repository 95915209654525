<template>
  <main
    class="color-primary h-100 page_bg_grey blank-aside-js customer-lists"
    :class="isOpenAside == 'true' ? 'blank-aside' : ''"
  >
    <v-dialog v-model="isShowDialog" max-width="300" persistent>
      <v-card>
        <v-radio-group v-model="customerSelectType" class="customer-type" v-if="!isCreateUser && similarCustomers.length">
          <v-radio
            label="Create New"
            value="0"
          ></v-radio>
          <v-radio
            label="Assign to existing one"
            value="1"
          ></v-radio>
        </v-radio-group>
        <v-card-title>{{ isCreateUser ? 'New User Form' : 'New Reseller Form' }}</v-card-title>

        <v-card-text>
          <form
            id="new-reseller-form"
            submit="onSubmitForm"
            v-on:submit.prevent="onSubmitForm"
          >
            <div v-if="!isCreateUser">
              <v-text-field
                v-model="business_license_number"
                v-bind:label="this.$t('Authorized Dealer / H.P.')"
                v-if="customerSelectType=='0'"
              ></v-text-field>

              <v-text-field
                v-model="business_name"
                label="Business Name"
                v-if="customerSelectType=='0'"
              ></v-text-field>
              <div class="filter-item mb-7 p-relative" v-if="customerSelectType=='1'"> 
                <label class="mb-2">{{
                  $t('Distributor')
                }}</label>
                <vue-select
                  :options="scustomersDropdown.options"
                  class="input cus-select scustomer-dropdown"
                  v-model="scustomersDropdown.selected"
                ></vue-select>
              </div> 
            </div>
            <v-text-field
              v-model="username"
              label="Username"
              minLength="4"
              maxLength="15"
            ></v-text-field>

            <v-text-field
              v-model="password"
              label="Password"
              minLength="4"
              maxLength="15"
            ></v-text-field>
          </form>
        </v-card-text>

        <v-card-text v-if="createResellerAndUserError"
          >An error occoured: {{ createResellerAndUserError }}</v-card-text
        >
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            text
            :disabled="createResellerAndUserLoading"
            :loading="createResellerAndUserLoading"
            type="submit"
            form="new-reseller-form"
          >
            create
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="closeDialog"
            :disabled="createResellerAndUserLoading"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <p class="main-title weight-700">
      {{ $t('Shaniv customers') }}
    </p>
    <section class="filter-part">
      <div class="d-flex flex-wrap align-items-end">
        <div class="filter-item p-relative">
          <label class="label">{{
            $t('List name')
          }}</label>
          <vue-select
            :options="distribute.options"
            class="input cus-select mb-2"
            v-model="distribute.selected"
          ></vue-select>
        </div>
        <div class="filter-item p-relative">
          <label class="label">{{
            $t('An existing customer at FlaPaid')
          }}</label>
          <vue-select
            :options="distribute.options"
            class="input cus-select mb-2"
            v-model="distribute.selected"
          ></vue-select>
        </div>
        <div class="filter-item middle-input">
          <label class="label">{{
            $t('Flappy customer tax')
          }}</label>
          <div class="button-input-div">
            <input
              class="input mb-2"
              v-model="temp"
              type="text"
              :placeholder="
                $t('Search customer number')
              "
              @keypress="isNumber($event)"
            />
            <button class="button filter__button"></button>
          </div>
        </div>
        <div class="filter-item middle-input">
          <label class="label">{{
            $t('Mobile Phone')
          }}</label>
          <div class="button-input-div">
            <input
              class="input mb-2"
              v-model="temp"
              type="text"
              :placeholder="
                $t('Mobile phone for searching')
              "
              @keypress="isNumber($event)"
            />
            <button class="button filter__button"></button>
          </div>
        </div>
        <div class="filter-item middle-input">
          <label class="label">{{
            $t('H.P. / Authorized Dealer')
          }}</label>
          <div class="button-input-div">
            <input
              class="input mb-2"
              v-model="temp"
              type="text"
              @keypress="isNumber($event)"
            />
            <button class="button filter__button"></button>
          </div>
        </div>
      </div>
    </section>
    <section class="table-content-part">
      <div class="d-flex justify-content-between">
        <div class="search-bar">
          <p class="search-label">{{ $t('Search') }}</p>
          <v-text-field
            v-model="search"
            single-line
            hide-details
            class="search-form"
            @input="debounceSearch"
          ></v-text-field>
        </div>
        <div>
          <button class="primary-btn clear_filter_btn" @click="clearFilter">{{ $t('Clear filters') }}</button>
        </div>
      </div>
      <v-data-table
        :headers="headers"
        :items="customers.data"
        class="cus-table editable-table main-table striped-table"
        item-key="id"
        :loading="getCustomersLoading"
        :options.sync="options"
        :server-items-length="customers.total"
        :footer-props="{
          'items-per-page-options': itemsPerPageOptions
        }"
      >
        <template v-slot:header.business_id="{ header }">
          {{ header.text }}
          <v-text-field v-model="filters.business_id"
                        type="text"
                        @input="debounceSearch"></v-text-field>
        </template>
        <template v-slot:header.CUSTNAME="{ header }">
          {{ header.text }}
          <v-text-field v-model="filters.CUSTNAME"
                        type="text"
                        @input="debounceSearch"></v-text-field>
        </template>
        <template v-slot:header.CUSTNAME="{ header }">
          {{ header.text }}
          <v-text-field v-model="filters.CUSTNAME"
                        type="text"
                        @input="debounceSearch"></v-text-field>
        </template>
        <template v-slot:header.WTAXNUM="{ header }">
          {{ header.text }}
          <v-text-field v-model="filters.WTAXNUM"
                        type="text"
                        @input="debounceSearch"></v-text-field>
        </template>
        <template v-slot:[`item.business_id`]="{ item }">
          <span v-if="item.business_id">{{ item.business_id }}</span>
          <button
            class="primary-btn fs14"
            @click="openBecomeCustomerModal(item)"
            v-if="item.business_id==null"
          >
            {{ $t('Become a customer') }}
          </button>
          <button
            class="primary-btn fs14"
            @click="openBecomeCustomerModal(item)"
            v-else-if="false && item.business_id!=null && item.user_id==null"
          >
            {{ $t('Create user') }}
          </button>
        </template>
      </v-data-table>
    </section>
    <section class="botton-action">
      <button class="primary-btn">
        {{ $t('Expand to full table') }}
      </button>
      <button class="primary-btn bg-yellow2">
        {{ $t('Uploading a new list') }}
      </button>
    </section>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
  </main>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'CustomerLists',
  data() {
    return {
      search: '',
      headers: [
        {
          text: this.$t('Tax.client') + '\n' + 'CUSTNAME',
          align: 'center',
          sortable: false,
          // value: 'cust_name',
          value: 'CUSTNAME',
        },
        {
          text: this.$t('Business name') + '\n' + 'CUSTDES',
          align: 'center',
          sortable: false,
          // value: 'custdes',
          value: 'CUSTDES',
        },
        {
          text: this.$t('City') + '\n' + 'STATE',
          align: 'center',
          sortable: false,
          value: 'STATE',
        },
        {
          text: this.$t('Address') + '\n' + 'ADDRESS',
          align: 'center',
          sortable: false,
          value: 'ADDRESS',
        },
        {
          text: this.$t('Phone') + '\n' + 'PHONE',
          align: 'center',
          sortable: false,
          value: 'PHONE',
        },
        {
          text: this.$t('Sector') + '\n' + 'CTYPENAME',
          align: 'center',
          sortable: false,
          value: 'CTYPENAME',
        },
        {
          text: this.$t('Area code') + '\n' + 'CTYPE2CODE',
          align: 'center',
          sortable: false,
          value: 'CTYPE2CODE',
        },
        {
          text: this.$t('Region name') + '\n' + 'CTYPE2NAME',
          align: 'center',
          sortable: false,
          value: 'CTYPE2NAME',
        },
        {
          text:
            this.$t('Authorized Dealer / H.P.') + '\n' + 'WTAXNUM',
          align: 'center',
          sortable: false,
          value: 'WTAXNUM',
        },
        {
          text: this.$t('Agent code') + '\n' + 'AGENTCODE',
          align: 'center',
          sortable: false,
          value: 'AGENTCODE',
        },
        {
          text: this.$t('Agent name') + '\n' + 'AGENTNAME',
          align: 'center',
          sortable: false,
          value: 'AGENTNAME',
        },
        {
          text: this.$t('Obligo') + '\n' + 'MAX_OBLIGO',
          align: 'center',
          sortable: false,
          value: 'MAX_OBLIGO',
        },
        {
          text: this.$t('PAYDES') + '\n' + 'PAYDES',
          align: 'center',
          sortable: false,
          value: 'PAYDES',
        },
        {
          text: this.$t('Company'),
          align: 'center',
          sortable: false,
          value: 'company',
        },
        {
          text: this.$t('Platok customer'),
          align: 'center',
          sortable: false,
          value: 'business_id',
        },
      ],
      filters: {},
      distribute: {
        options: [],
        selected: '',
      },
      scustomersDropdown: {
        options: [],
        selected: '',
      },
      temp: '',
      rules: {
        required: (value) => !!value || 'Required.',
        min4: (value) => value?.length >= 4 || 'Min 4 characters',
        max15: (value) => value?.length <= 15 || 'Max 15 characters',
      },
      itemsPerPageOptions: [10, 15, 25, 50, 100],
      options: {
        itemsPerPage: 10,
        page: 1
      },
      customerSelectType: "0",
      isCreateUser: false,
      isShowDialog: false
    };
  },
  async created() {
    if (this.customers.length == 0) {
      await this.getCustomersList(1);
    }
  },
  computed: {
    ...mapState({
      getCustomersLoading: (state) =>
        state.shaniv_management.getCustomersLoading,
      createResellerAndUserLoading: (state) =>
        state.shaniv_management.createResellerAndUserLoading,
      moduleLoading: (state) =>
        state.shaniv_management.loading,
      customers: (state) => state.shaniv_management.customers,
      selectedCustomer: (state) => state.shaniv_management.selectedCustomer,
      createResellerAndUserError: (state) =>
        state.shaniv_management.createResellerAndUserError,
      businessForm: (state) => state.shaniv_management.businessForm,
      userForm: (state) => state.shaniv_management.userForm,
      similarCustomers: (state) => state.shaniv_management.similarCustomers,
    }),

    loading() {
      return this.getCustomersLoading || this.createResellerAndUserLoading || this.moduleLoading
    },

    business_license_number: {
      get() {
        return this.$store.state.shaniv_management.businessForm
          .business_license_number;
      },
      set(value) {
        this.$store.commit('shaniv_management/updateResellerFormField', {
          formName: 'businessForm',
          formField: 'business_license_number',
          newValue: value,
        });
      },
    },
    business_name: {
      get() {
        return this.$store.state.shaniv_management.businessForm.name;
      },
      set(value) {
        this.$store.commit('shaniv_management/updateResellerFormField', {
          formName: 'businessForm',
          formField: 'name',
          newValue: value,
        });
      },
    },
    username: {
      get() {
        return this.$store.state.shaniv_management.userForm.username;
      },
      set(value) {
        this.$store.commit('shaniv_management/updateResellerFormField', {
          formName: 'userForm',
          formField: 'username',
          newValue: value,
        });
      },
    },
    password: {
      get() {
        return this.$store.state.shaniv_management.userForm.password;
      },
      set(value) {
        this.$store.commit('shaniv_management/updateResellerFormField', {
          formName: 'userForm',
          formField: 'password',
          newValue: value,
        });
      },
    },
  },
  methods: {
    ...mapActions('shaniv_management', {
      getCustomers: 'getCustomers',
      setSelectedCustomer: 'setSelectedCustomer',
      clearSelectedUser: 'clearSelectedUser',
      createResellerAndUser: 'createResellerAndUser',
      getSimilarCustomer: 'getSimilarCustomer',
      updateResellerAndUser: 'updateResellerAndUser'
    }),
    ...mapActions('alert', {
      successAlert: 'success',
      errorAlert: 'error',
      infoAlert: 'info',
    }),
    addReseller: function (CUSTNAME) {
      console.log(`addResller - CUSTNAME = ${CUSTNAME}`);
    },
    async onSubmitForm(e) {
      e.preventDefault();
      if (this.isCreateUser) {
        await this.updateResellerAndUser(this.selectedCustomer.business_id);
      } else {
        if (this.customerSelectType=="0") {
          await this.createResellerAndUser();
        } else  {
          await this.updateResellerAndUser(this.scustomersDropdown.selected.code);
        }
      }
      await this.getCustomersList(1);

      if (!this.createResellerAndUserError) {
        this.isShowDialog = false;
        this.successAlert();
      }
    },
    getCustomersList(page) {
      page = +page || +this.options.page;
      this.options.page = page;
      let itemsPerPage = +this.options.itemsPerPage;
      this.getCustomers({
        per_page: itemsPerPage, 
        page: page-1,
        search_term: this.search,
        ...this.filters
      });
    },
    debounceSearch() {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.getCustomersList(1);
      }, 1000)
    },
    async openBecomeCustomerModal(item) {
      if (item.business_id!=null && item.user_id==null) {
        this.isCreateUser = true;
      } else {
        this.isCreateUser = false;
        await this.getSimilarCustomer(item.id)
        if (this.similarCustomers.length) {
          this.scustomersDropdown.options = [];
          this.similarCustomers.forEach(el => {
            this.scustomersDropdown.options.push({
              label: el.CUSTNAME + " " + el.CUSTDES + " " + el.PLNAME,
              code: el.business_id
            })
          });
          this.scustomersDropdown.selected = this.scustomersDropdown.options[0];
        }  
      }
      this.setSelectedCustomer(item);
      this.isShowDialog = true;
    },
    closeDialog() {
      this.clearSelectedUser();
      this.isShowDialog = false;
    },
    clearFilter() {
      this.search = '';
      for (let filter_name in this.filters) {
        this.filters[filter_name] = '';
      }
      this.getCustomersList(1);
    }
  },
  watch: {
    options: {
      handler () {
        this.getCustomersList();
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss">
.customer-lists .table-content-part .clear_filter_btn {
  margin: 0 auto;
  min-width: 200px;
}
.cus-table thead th {
  vertical-align: top;
}
.filter-part {
  .filter-item {
    padding: 0 15px;
  }
  padding: 0 35px;
  .cus-select {
    min-width: 230px;
    padding: 0 7px;
    .vs__selected {
      font-size: 17px;
      color: #002036;
      font-weight: 300;
      height: 80%;
      margin: 2px;
      margin-top: 4px;
    }
  }
  .middle-input {
    max-width: 300px;
  }
  .button-input-div {
    display: flex;
    input[type='text'] {
      width: calc(100% - 45px);
    }

    button {
      width: 45px;
      cursor: pointer;
    }
  }
  .filter__button {
    margin-top: 0 !important;
  }
}

.botton-action {
  display: flex;
  flex-wrap: wrap;
  margin: 30px 35px;
  .primary-btn {
    margin: 0 15px;
    width: 190px;
    height: 40px;
    font-size: 16px;
  }
}
.customer-type {
  padding-top: 10px;
  margin-top: 0;
}

.scustomer-dropdown {
  padding-left: 0;
  padding-right: 0;
  .vs__dropdown-menu {
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (max-width: 767px) {
  .filter-part {
    padding: 20px;
    .filter-item {
      width: 100%;
      padding: 0;
    }
    .middle-input {
      max-width: unset;
    }
  }
  .table-content-part {
    margin: 20px 20px 0;
  }
}
</style>